import React from "react"
import styled from "styled-components"

const Contact = styled.div``
Contact.Title = styled.p``
Contact.Email = styled.a``

export default ({ contact }) => {
  if (!contact) {
    return null
  }
  return (
    <Contact>
      <Contact.Title>{`${contact.name} ⟶`}</Contact.Title>
      <Contact.Email href="/">{contact.emailAddress}</Contact.Email>
    </Contact>
  )
}
